import React from 'react';

const SEO = () => {
  return (
    <>
      <title>Estrela Radiadores</title>
      <meta
        name="description"
        content="Somos especializados em manutenção e vendas de radiadores e peças de arrefecimento, sempre entregando produtos de qualidade, com preço justo e segurança."
      />
      <meta
        name="keywords"
        content="Estrela, Radiadores, Produtos, Oficina, Qualidade, Excelência, Serviços"
      />
      <meta name="author" content="Alexandre Novaes" />
      <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
    </>
  );
};

export default SEO;
