import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Title from '../Title';
import {
  description,
  imageContainer,
  image,
  //@ts-ignore
} from './style.module.css';

const About = () => {
  return (
    <div>
      <Title text="Quem Somos" />
      <StaticImage
        src="../../assets/images/entrada-loja.jpeg"
        alt="Foto da entrada da loja"
        placeholder="blurred"
        className={imageContainer}
        imgClassName={image}
      />
      <div className={description}>
        <article>
          Criada em <strong>1989</strong>, a <strong>Estrela Radiadores</strong>{' '}
          veio com o objetivo de oferecer ao mercado as melhores soluções em
          Radiadores, atuando com
          <strong> excelência</strong> e sendo referência no Estado de Alagoas
          atendendo a grandes empresas, usinas e também o grande público. Hoje
          já entregamos mais de{' '}
          <strong>100 mil soluções entre produtos e serviços.</strong>
        </article>
      </div>
    </div>
  );
};

export default About;
