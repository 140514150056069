import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
//@ts-ignore
import { productContainer, productImage, title } from './Product.module.css';

type ProductItemProps = {
  imageData: any;
  alt: string;
  productTitle: string;
};

const ProductItem: FC<ProductItemProps> = ({
  imageData,
  alt,
  productTitle,
}) => {
  return (
    <article className={productContainer}>
      <GatsbyImage image={imageData} alt={alt} className={productImage} />
      <h3 className={title}>{productTitle || 'Sem título'}</h3>
    </article>
  );
};

export default ProductItem;
