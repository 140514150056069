import React, { useState } from 'react';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import Navbar from '../components/Navbar';
import Introduction from '../components/Introduction';
import Title from '../components/Title';
import { WhatsAppButton } from '../components/Button';
import ProductItem from '../components/ProductItem';
import {
  h2Container,
  whatsAppButtonContainer,
  produtosDescriptionContainer,
  produtosList,
  avaliacoesImage,
  avaliacoesDescription,
  galleryImagesContainer,
  galleryDescription,
  spaceNavbar,
  spaceIntroduction,
  //@ts-ignore
} from '../styles/mainpage.module.css';
import { graphql, useStaticQuery } from 'gatsby';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import { WHATSAPP_LINK } from '../configuracoes';
import Badges from '../components/Badges';
import LateralSpaces from '../components/LateralSpaces';
import SEO from '../components/SEO';
import About from '../components/About';

const IndexPage = () => {
  const [menuState, setMenu] = useState<boolean>(false);

  const handleClickNavMenu = () => {
    setMenu((prev) => !prev);
  };

  const query = useStaticQuery(graphql`
    query ProductsAndGallery {
      allMarkdownRemark {
        edges {
          node {
            id
            frontmatter {
              titulo
              title
              ordem
              imagemAlt
              caminhoParaImagem {
                id
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }

      allFile(filter: { name: { regex: "/photo/" } }) {
        edges {
          node {
            name
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const products = query.allMarkdownRemark.edges.map((edge: any) => {
    const { titulo, caminhoParaImagem, ordem, imagemAlt } =
      edge.node.frontmatter;

    return {
      title: titulo,
      src: caminhoParaImagem.childImageSharp.gatsbyImageData,
      id: caminhoParaImagem.id,
      order: ordem,
      alt: imagemAlt,
    };
  });

  products.sort((a: any, b: any) => a.order - b.order);

  const gallery = query.allFile.edges.map((edge: any) => {
    const { name, id, childImageSharp } = edge.node;

    return {
      name,
      id,
      imageData: childImageSharp.gatsbyImageData,
    };
  });

  return (
    <>
      <div className={spaceNavbar}>
        <Navbar onClickMenuButton={handleClickNavMenu} />
      </div>
      <Menu activeMenu={menuState} onClick={handleClickNavMenu} />
      <main>
        <div className={spaceIntroduction}>
          <Introduction />
        </div>
        <div className={`${h2Container} hiddenDesktop`}>
          <h2>
            Aqui você encontra produtos de qualidade para seu veículo com
            garantia e preço justo!
          </h2>
        </div>
        <div
          className={whatsAppButtonContainer}
          style={menuState ? { width: '0' } : {}}
        >
          <WhatsAppButton
            text="Entre em contato!"
            onClick={() => window.open(WHATSAPP_LINK, '_blank')}
          />
        </div>
        <div>
          <Badges />
        </div>
        <section id="mQuemSomos">
          <About />
        </section>
        <section id="nossosProdutos">
          <Title text="Nossos Produtos" />
          <LateralSpaces>
            <div className={produtosDescriptionContainer}>
              <p>
                Possuímos uma linha completa de Radiadores para carros,
                caminhões, vans e máquinas, além de Colméias diversas e peças
                para arrefecimento.
              </p>
              <p style={{ marginBottom: '60px' }}>
                Veja abaixo em nossa lista:
              </p>
            </div>
            <div className={produtosList}>
              {products.map(
                (product: {
                  title: string;
                  order: number;
                  src: any;
                  id: string;
                  alt: string;
                }) => {
                  const { src, title, id, alt } = product;

                  return (
                    <ProductItem
                      key={id}
                      imageData={src}
                      alt={alt}
                      productTitle={title}
                    />
                  );
                }
              )}
            </div>
          </LateralSpaces>
        </section>
        <section id="avaliacoes">
          <Title text="Avaliações" />
          <LateralSpaces>
            <div>
              <p className={avaliacoesDescription}>
                Veja avaliações dos nossos clientes encantados com nossos
                serviços:
              </p>
              <StaticImage
                src="../../conteudo/avaliacoes/avaliacoes.png"
                alt="Avaliações de clientes satisfeitos"
                className={avaliacoesImage}
              />
            </div>
          </LateralSpaces>
        </section>
        <section id="galeria">
          <Title text="Galeria" />
          <LateralSpaces>
            <p className={galleryDescription}>
              Vejam algumas fotos dos nossos negócios e serviços:
            </p>
            <div className={galleryImagesContainer}>
              {gallery.map(
                (photo: { name: string; id: string; imageData: any }) => {
                  return (
                    <GatsbyImage
                      key={photo.id}
                      alt={photo.name}
                      image={photo.imageData}
                    />
                  );
                }
              )}
            </div>
          </LateralSpaces>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default IndexPage;

export const Head = () => {
  return <SEO />;
};
