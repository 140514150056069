import React from 'react';
import { ClientIcon, QualityIcon, ServicesIcon } from '../SVGs';
//@ts-ignore
import { container } from './badges.module.css';

const Badges = () => {
  return (
    <section id="quemSomos" className={container}>
      <div>
        <QualityIcon />
        <h2>
          Atuamos com <strong>excelência</strong>, sendo referência no Estado de{' '}
          <strong>Alagoas.</strong>
        </h2>
      </div>
      <div>
        <ServicesIcon />
        <h2>
          Hoje já entregamos mais de{' '}
          <strong>100 mil soluções entre produtos e serviços.</strong>
        </h2>
      </div>
      <div>
        <ClientIcon />
        <h2>Atendemos a grandes empresas, usinas e ao público geral.</h2>
      </div>
    </section>
  );
};

export default Badges;
