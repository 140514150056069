import React from 'react';
//@ts-ignore
import { container, bars } from './lateral.module.css';

type Props = {
  width?: string;
  children?: React.ReactNode;
};

const LateralSpaces: React.FC<Props> = ({ width, children }) => {
  return (
    <div className={container}>
      <section className={bars}></section>
      <div>{children}</div>
      <section className={bars}></section>
    </div>
  );
};

export default LateralSpaces;
