// extracted by mini-css-extract-plugin
export var avaliacoesDescription = "mainpage-module--avaliacoesDescription--NQpw-";
export var avaliacoesImage = "mainpage-module--avaliacoesImage--F8eYt";
export var galleryDescription = "mainpage-module--galleryDescription--uOEz-";
export var galleryImagesContainer = "mainpage-module--galleryImagesContainer--YPgPy";
export var h2Container = "mainpage-module--h2Container--zYsSP";
export var produtosDescriptionContainer = "mainpage-module--produtosDescriptionContainer--f4mDH";
export var produtosList = "mainpage-module--produtosList--vXccS";
export var quemSomosDescriptionContainer = "mainpage-module--quemSomosDescriptionContainer--cNOtC";
export var quemSomosImage = "mainpage-module--quemSomosImage--+eDjU";
export var quemSomosImageContainer = "mainpage-module--quemSomosImageContainer--qKG8X";
export var spaceIntroduction = "mainpage-module--spaceIntroduction--wYqfA";
export var spaceNavbar = "mainpage-module--spaceNavbar--pbDRH";
export var whatsAppButtonContainer = "mainpage-module--whatsAppButtonContainer--vQHTK";